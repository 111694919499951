import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

// import material ui
import { Select } from "@material-ui/core";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { MenuItem, InputLabel } from "@material-ui/core";

// import action
import { updateToken, gettokendata } from "../../actions/users";

// import lib
import fileObjectUrl from "../../lib/fileObjectUrl";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

const styles = (theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  textDanger: {
    color: "#f32828",
  },
});

const initialFormValue = {
  type: "",
  currencyName: "",
  currencySymbol: "",
  tokenType: "",
  decimals: "",
  withdrawFee: "",
  contractAddress: "", // if token
  currencyId: "",
};
const useStyles = makeStyles(styles);

const TokenUpdate = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const {
    type,
    currencyName,
    currencySymbol,
    tokenType,
    withdrawFee,
    decimals,
    contractAddress,
    currencyId,
  } = formValue;

  // function

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  // console.log(params.id, 'paramsparamsparams')

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      let data = {
        id: params.id,
        currencyName,
        currencySymbol,
        type,
        //  withdrawFee
        decimals,
        contractAddress,
        tokenType,
      };

      // console.log(data, 'reqdataaaaaaa')
      const { status, loading, messages, error } = await updateToken(data);
      if (status == "success") {
        // console.log(error)
        toastAlert("success", messages, "addCurrency");
        history.push("/Tokenlist");
      } else {
        setValidateError(error);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const fetchToken = async () => {
    try {
      // console.log(params.id, 'id')
      const { status, loading, error, result } = await gettokendata(params.id);
      if (status == "success") {
        // console.log(result, 'result')
        let data = {
          type: result.type,
          currencyName: result.currencyName,
          currencySymbol: result.currencySymbol,
          withdrawFee: result.withdrawFee,
          tokenType: result.tokenType,
          decimals: result.decimals,
          contractAddress: result.contractAddress,
        };
        setFormValue(data);
      } else {
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              method="POST"
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Update Token</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {/* <GridItem xs={12} sm={12} md={4}>
                    <div className="custom_select">
                      <InputLabel id="demo-controlled-open-select-label">Currency Type</InputLabel>
                      <Select
                        className="d-block"
                        labelText="Currency Symbol"
                        name="type"
                        value={type}
                        onChange={handleChange}
                      >
                        <MenuItem value={"fiat"}>Fiat</MenuItem>
                        <MenuItem value={"crypto"}>Crypto</MenuItem>
                        <MenuItem value={"token"}>Token</MenuItem>
                      </Select>
                    </div>

                    {
                      validateError.type && <span className={classes.textDanger}>{validateError.type}</span>
                    }

                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Currency Type"
                      name="type"
                      value={type}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Currency Name"
                      name="currencyName"
                      value={currencyName}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.currencyName && (
                      <span className={classes.textDanger}>
                        {validateError.currencyName}
                      </span>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Currency Symbol"
                      name="currencySymbol"
                      value={currencySymbol}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.currencySymbol && (
                      <span className={classes.textDanger}>
                        {validateError.currencySymbol}
                      </span>
                    )}
                  </GridItem>

                  {/* <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="Withdraw Fee"
                                            name="withdrawFee"
                                            value={withdrawFee}
                                            onChange={handleChange}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                        {
                                            validateError.withdrawFee && <span className={classes.textDanger}>{validateError.withdrawFee}</span>
                                        }
                                    </GridItem> */}

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Token Type"
                      name="tokenType"
                      value={tokenType}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.tokenType && (
                      <span className={classes.textDanger}>
                        {validateError.tokenType}
                      </span>
                    )}
                    {/* <div className="custom_select">
                                            <InputLabel id="demo-controlled-open-select-label">Token Type</InputLabel>

                                            <Select
                                                className="d-block"
                                                name="tokenType"
                                                value={tokenType}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={"ERC20"}>ERC20</MenuItem>
                                                <MenuItem value={"TRC20"}>TRC20</MenuItem>
                                                <MenuItem value={"BEP20"}>BEP20</MenuItem>
                                            </Select>

                                          
                                        </div> */}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Contract Address"
                      name="contractAddress"
                      value={contractAddress}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.contractAddress && (
                      <span className={classes.textDanger}>
                        {validateError.contractAddress}
                      </span>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Decimal"
                      name="decimals"
                      value={decimals}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.decimals && (
                      <span className={classes.textDanger}>
                        {validateError.decimals}
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default TokenUpdate;
