// import axios
import axios from 'axios';

// import lib
import config from '../lib/config';
import { getAuthToken } from '../lib/localStorage'

axios.defaults.headers.common['Authorization'] = getAuthToken();

console.log(config ,'url')
export const addCurrency = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/adminapi/currency`,
            data
        });
        return {
            status: "success",
            loading: true,
            messages: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const updateCurrency = async (reqData) => {
    try {
        // console.log(reqData,'dataaaaaaaaa')
        let respData = await axios({
            'method': 'put',
            'url': `${config.baseUrl}/adminapi/currency`,
            'data': reqData
        });
        return {
            status: "success",
            loading: false,
            messages: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const deleteCurrency = async (currencyId) => {
    try {
        // console.log(currencyId,'currencyId')
        let respData = await axios({
            'method': 'delete',
            'url': `${config.baseUrl}/adminapi/currency-delete/` + currencyId,
        });

        return {
            status: "success",
            loading: false,
            messages: respData.data.message,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getSingleCurrency = async (currencyId) => {
    try {
        // console.log(currencyId,"currencyIdcurrencyId")
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/adminapi/getSingleCurrency/` + currencyId,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getCurrencyDropDown = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getCurrency`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getAllCurrencies = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/adminapi/currency`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}