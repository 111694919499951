import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

// import material ui
import { Select } from "@material-ui/core";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { MenuItem, InputLabel } from "@material-ui/core";

// import action
import { priceCNVUpdate, getPriceCnvdata } from "../../actions/priceCNVAction";

// import lib
import fileObjectUrl from "../../lib/fileObjectUrl";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

const styles = (theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  textDanger: {
    color: "#f32828",
  },
});

const initialFormValue = {
  priceCNVId: "",
  firstCurrency: "",
  secondCurrency: "",
  convertPrice: "",
};
const useStyles = makeStyles(styles);

const EditPriceconversion = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const { firstCurrency, secondCurrency, convertPrice } = formValue;

  // function

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const reqData = {
        priceCNVId: params.id,
        firstCurrency,
        secondCurrency,
        convertPrice,
      };
      const { status, loading, message, error } = await priceCNVUpdate(reqData);
      if (status == "success") {
        // console.log(error)
        toastAlert("success", message, "addCurrency");
        history.push("/PriceCnvList");
      } else {
        setValidateError(error);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const fetchSingleCurrency = async () => {
    try {
      // console.log(params, "currencyId");
      const { status, loading, error, result } = await getPriceCnvdata(
        params.id
      );
      if (status == "success") {
        // console.log(result, 'result')
        let data = {
          firstCurrency: result.firstCurrency,
          secondCurrency: result.secondCurrency,
          convertPrice: result.convertPrice,
        };
        setFormValue(data);
      } else {
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    fetchSingleCurrency();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              method="POST"
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Update Currency</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="firstCurrency"
                      name="firstCurrency"
                      value={firstCurrency}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="SecondCurrency"
                      name="secondCurrency"
                      value={secondCurrency}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="ConvertPrice"
                      name="convertPrice"
                      value={convertPrice}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.convertPrice && (
                      <span className={classes.textDanger}>
                        {validateError.convertPrice}
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default EditPriceconversion;
