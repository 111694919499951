import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, RowsProp, ColDef } from '@material-ui/data-grid';
import { Paper, Button } from '@material-ui/core';
import {
    Edit as EditIcon,
    Visibility as VisibilityIcon
} from '@material-ui/icons';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import configSet from "../../lib/config"
// import action
import { getTicketData,closeTicket,  replyMessage } from '../../actions/support';

// import lib
import { replyValidation } from './validation';
import { firstLetterCase } from '../../lib/capitalize';
import isEmpty from '../../lib/isEmpty';
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    flexHeader: {
        display: "flex !important",
        justifyContent: "space-between !important",
        alignItems: "center !important"
    },
    btnPrimary: {
        backgroundColor: "#b462c5",
        color: "#ffffff !important",
        padding: "7px 15px",
        fontSize: "12px",
        fontWeight: "700"
    },
    textDanger: {
        color: "#f32828",
    },
    spacing:{
        marginRight: "10px"
    }
};
const useStyles = makeStyles(styles);

const initialFormValue = {
    'message': '',
}

const TicketChat = (props) => {
    const classes = useStyles();
    const { ticketId } = useParams();
    const history = useHistory();
    // state
    const [detail, setDetail] = useState([])
    const [messageList, setMessageList] = useState([])
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});

    const { message } = formValue;

    // function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
        setValidateError(replyValidation(formData))
    }

  

    const fetchTicketData = async () => {
        try {
            const { status, loading, error, result } = await getTicketData(ticketId)
            if (status == 'success') {
                setDetail(result.data)
                console.log(result,'rew1')
                
            }
        } catch (err) { }
    }

    const handleSubmit = async () => {
        let reqData = {
            message,
            'receiverId': detail.userId,
            'ticketId': ticketId
        }

        try {
            const { status, loading, error,messages, result } = await replyMessage(reqData);
            console.log(error,'=-0')
            if (status == 'success') {
               
                history.push('/support/ticket')
                setFormValue(initialFormValue)
                setMessageList(result);
                setValidateError(replyValidation(initialFormValue))
            }
            else {
                setValidateError(error);
              }
        } catch (err) { }
    }
const handleClose = async ()=>{
    
    try {
        const { status, loading, error, result } = await closeTicket(ticketId);
        if (status == 'success') {
            history.push('/support/ticket')
        }
    } catch (err) { }
}
    useEffect(() => {
        if (!isEmpty(ticketId)) {
            
            fetchTicketData();
        }
        setValidateError(replyValidation(formValue))
    }, [])

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Card>
                    {console.log(detail,'000')}
                    <CardHeader color="primary">
                        <div className={classes.flexHeader}>
                            <h4>Category:{detail && detail.category}</h4>
                            <h4>Status:{detail && detail.status}</h4>
                            
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ul className="ticketComments">
                            {
                                detail.reply && detail.reply.length > 0 && detail.reply.map((item, key) => {
                                    
                                    return (
                                        <li key={key}>
                                            <div className="ticketUserDetails">
                                            <p className="metaChatDetails">senderId  {item.senderId}</p>
                                          {
                                            item && item.attachment !='' && <div><p className="metaChatDetails">Attachment</p> <img src={configSet.baseUrl+"/images/support-ticket/"+item.attachment} width='140px'height='110px'/> </div>
                                          }
                                            
                                
                                            </div>
                                            <div className="ticketDetails">
                                                <p className="metaChatDetails">Created  on {item.createdAt}</p>
                                                <p>{item.message}</p>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        
                        {
                            detail && detail.status != 'closed' && <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    labelText="Reply to user"
                                    onChange={handleChange}
                                    value={message}
                                    name="message"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    
                                />
                         {validateError.message && (
                      <span className={classes.textDanger}>
                        {validateError.message}
                      </span>
                    )}
                            </GridItem>
                        }

                        {
                            detail && detail.status != 'closed' && <GridItem xs={12} sm={12} md={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    className={classes.spacing}
                                  
                                >
                                    Reply
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClose}
                                >
                                    Close Ticket
                                </Button>
                            </GridItem>
                        }


                    </CardBody>
                </Card>
            </Paper>
        </div >
    )
}

export default TicketChat;